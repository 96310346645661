exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-accessibility-js": () => import("./../../../src/pages/accessibility.js" /* webpackChunkName: "component---src-pages-accessibility-js" */),
  "component---src-pages-blog-comments-to-ncua-js": () => import("./../../../src/pages/blog/Comments-to-NCUA.js" /* webpackChunkName: "component---src-pages-blog-comments-to-ncua-js" */),
  "component---src-pages-blog-good-profits-for-good-loans-js": () => import("./../../../src/pages/blog/Good-Profits-for-Good-Loans.js" /* webpackChunkName: "component---src-pages-blog-good-profits-for-good-loans-js" */),
  "component---src-pages-blog-help-borrowers-pay-off-js": () => import("./../../../src/pages/blog/Help-Borrowers-Pay-off.js" /* webpackChunkName: "component---src-pages-blog-help-borrowers-pay-off-js" */),
  "component---src-pages-blog-middle-income-consumers-demand-small-dollar-loans-js": () => import("./../../../src/pages/blog/Middle-Income-Consumers-Demand-Small-Dollar-Loans.js" /* webpackChunkName: "component---src-pages-blog-middle-income-consumers-demand-small-dollar-loans-js" */),
  "component---src-pages-blog-record-profits-invest-in-your-future-js": () => import("./../../../src/pages/blog/Record-Profits-Invest-In-Your-Future.js" /* webpackChunkName: "component---src-pages-blog-record-profits-invest-in-your-future-js" */),
  "component---src-pages-clients-js": () => import("./../../../src/pages/clients.js" /* webpackChunkName: "component---src-pages-clients-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-media-jsx": () => import("./../../../src/pages/media.jsx" /* webpackChunkName: "component---src-pages-media-jsx" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-resources-js": () => import("./../../../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-security-js": () => import("./../../../src/pages/security.js" /* webpackChunkName: "component---src-pages-security-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-why-js": () => import("./../../../src/pages/why.js" /* webpackChunkName: "component---src-pages-why-js" */)
}

